<template>
    <div>
        <div class="mask" v-show="isShow" @click="closeLightbox"></div>
        <form
            class="qrcode-lightbox"
            v-show="isShow"
        >
            <button
                type="button"
                class="btn-close"
                @click="closeLightbox"
            ></button>
            <div class="lightbox-header">
                <div class="title">預約當日有效：{{ customerName }}
                </div>
            </div>
            <div class="lightbox-body">
                <div class="content d-flex justify-content-center mb-4">   
                    <img :src="img" alt="">
                </div>
                <div class="content d-flex justify-content-center">   
                    <a :href="url" target="_blank" class="text-center url">
                        {{ url }}
                    </a>
                    <a
                        href="javascript:;"
                        class="link-bar__tool"
                        @click="copyToClipboard(url)"
                    >
                        <img
                            src="@/assets/tgd/index/copy.svg"
                            alt=""
                            srcset=""
                        >
                    </a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { reactive, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";

export default {
    name: "DivideClassLightbox",
    props: {
        isShow: Boolean,
        img: String,
        url: String,
        customerName: String,

    },
    emits: ["update:isShow"],
    setup(props, { emit }) {
        const { state } = useStore();
        function closeLightbox () {
            emit("update:isShow", false)
        }
        function copyToClipboard(text) {
            const el = document.createElement('textarea');
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            Swal.fire({
                title: "複製成功!",
                icon: "success",
                confirmButtonText: "確定",
            }).then(() => {
            });
        }

        return {
            closeLightbox,
            copyToClipboard
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.qrcode-lightbox {
    width: 100%;
    max-width: 680px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    box-shadow: 0 8px 21px 0 rgba(0, 0, 0, 0.51);
    background-color: #fff
}

.btn-close {
    @include size(28px);
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 1);
    background-image: unset;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 10;
    opacity: 1;
    box-sizing: border-box;

    &::before,
    &::after {
        content: "";
        display: inline-block;
        @include size(14px, 2px);
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.lightbox-header {
    .title {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        padding: 40px 0 20px;
        border-bottom: 1px solid rgba(112, 112, 112, .2);
    }
}
.lightbox-body {
    padding: 40px 0 20px;
  .content {
    width: 320px;
    margin: 0 auto;
    a {
        color: #333;
        &.url {
            word-break: break-all;
            height: 20px * 2;
            max-height: 20px * 2;
            line-height:  20px;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            display: -webkit-inline-box;
        }
    }
  }
}

.lightbox-footer {
    padding: 10px 33px 31px 33px;
    transition: all 0.3s ease;
    text-align: center;
}

.btn {
    display: inline-block;
    margin: 0 auto;
    width: 300px;
    border-radius: 11px;
    padding: 15px;
    text-align: center;
    background: transparent
        linear-gradient(
            102deg,
            #ab7e2b 0%,
            #f1e3b5 14%,
            #ab7e2b 64%,
            #c48109 100%,
        );
    box-shadow: 0px 3px 6px #00000029;
    color: #fff;
    border: 1px solid #ffffff85;
    p {
        width: 19.6px;
    }
}
</style>
